export const contactFormId = 'homepage_contact'

export const trustedBySectionId = 'homepage_trusted_by'

export const projectsCarouselId = 'homepage_projects'

export const jobOpeningsId = 'job_openings'

const siteDomain = process.env.NEXT_PUBLIC_APP_BASE_URL

export const defaultMeta = {
  title: 'The SilverLogic | Custom Software Development',
  description:
    'We are a value-driven, client-focused custom software development company leveraging efficient problem-solving skills to upgrade and grow your business.',
  image: `${siteDomain}/assets/logo_og.webp`,
}

export const servicesMeta = {
  title: 'The SilverLogic | What We Do',
  description:
    'The SilverLogic is a custom software engineering company and solution architect. We provide mobile apps, AR, blockchain, IoT, automation, custom solutions.',
  image: `${siteDomain}/assets/logo_og.webp`,
}

export const aboutMeta = {
  title: 'The SilverLogic | About Us',
  description:
    'The SilverLogic makes ideas happen, whether they require sophisticated apps, websites, business automation, artificial intelligence, or platforms.',
  image: `${siteDomain}/assets/logo_og.webp`,
}

export const portfolioMeta = {
  title: 'The SilverLogic | Portfolio',
  description:
    "Browse a sample of projects in The SilverLogic's portfolio. We highlight custom software in healthcare, remote controlled programming, real estate, and boating.",
  image: `${siteDomain}/assets/logo_og.webp`,
}

export const careerMeta = {
  title: 'The SilverLogic | Our Culture',
  description:
    'TSL offers perks and benefits that try to make your work-life just feel like life. We prioritize teamwork, collaboration and care about our culture.',
  image: `${siteDomain}/assets/logo_og.webp`,
}
